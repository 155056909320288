import React from 'react'

const Header = () => {
    return (
        <header className='py-3 text-center'>
            <h2 style={{'color': 'white'}}>Join us to transform more lives with the Rhapsody Bible!</h2>
        </header>
    )
}

export default Header
