import React, { useState, useEffect, Component } from 'react';
import { Container, Row, Col, Form, Image, Button, Spinner } from 'react-bootstrap';
import { CountryDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import { PaystackButton } from 'react-paystack'
import BMN from '../images/BMN.jpeg';
import Select from 'react-select';
import swal from '@sweetalert/with-react'
import Message from '../components/Message'
import { submitToApi, parseDate, currencyChecker } from '../services/Helpers';
import dotenv from 'dotenv'
import { loadStripe } from '@stripe/stripe-js';
import Calendar from 'react-calendar';
import { Countries } from '../services/Countries'
import Checks from '../components/Checks';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import Signin from './Signin'
dotenv.config();




const BibleMandateScreen = ({ match, location }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [formShow, setformShow] = useState(false);
    const [phone, setPhone] = useState('');
    const [bibles, setBibles] = useState(null);
    const [specifiedBibles, setSpecifiedBibles] = useState(null);
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [donateLater, setDonateLater] = useState(false)
    const [date, setDate] = useState(new Date())
    const [timeline, setTimeline] = useState('')
    const [amount, setAmount] = useState('')
    const [currency, setCurrency] = useState('')
    const [month, setMonth] = useState('')
    const [day, setDay] = useState('')
    const [show, setShow] = useState(false)

    const countryCode = (countryName) => {
        return Countries.find(({ Code, Name }) => Name.toLowerCase() === countryName.toLowerCase())?.Code.toLowerCase()
    }

    const referrer = location.pathname.split('/')[1]

    const countryString = countryCode(country)

    const getAmount = () => {
        switch (currency) {
            case "Pound":
                setAmount(`£${poundsAmount}`)
                break;
            case "Dollar":
                setAmount(`$${dollarAmount}`)
                break;
            case "Naira":
                setAmount(`₦${nairaAmount}`)
                break;
            case "CAD":
                setAmount(`CAD${cadAmount}`)
                break;
            case "Euro":
                setAmount(`€${euroAmount}`)
                break;
            case "Rand":
                setAmount(`R${randAmount}`)
                break;
            default: return amount
        }
    }

    useEffect(async () => {
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            swal("success", "Your donation has been recieved,God bless you", "success");
            let data = localStorage.getItem("data")
            let parseData = JSON.parse(data)
            submitToApi('https://biblemandate.onrender.com/api/success', { parseData })
        }
        if (query.get("canceled")) {
            swal(
                "failed", "Donation canceled, please try again", "error"
            );
        }
        getAmount()
        let dataTwo = localStorage.getItem("Userbibledata")
        let parseDataTwo = JSON.parse(dataTwo)

        if (dataTwo) {
            setEmail(parseDataTwo.email)
            setFirstName(parseDataTwo.firstName)
            setLastName(parseDataTwo.lastName)
            setCountry(parseDataTwo.country)
        }

    })

    const stripePromise = loadStripe("pk_live_GTa5tVhyJYNk1LaBtgXM7ZUl");
    //pk_test_ztlnJWwC80MRCnovpxsOyLZI
    //pk_live_GTa5tVhyJYNk1LaBtgXM7ZUl
    const getOptions = () => {
        const options = [];
        for (var i = 1; i < 101; i++) {
            const obj = {
                value: i,
                label: i === 1 ? `${i} Bible` : `${i} Bibles`
            }
            options.push(obj)
        }
        return [...options, { value: 'Specify', label: 'Specify' }]
    };

    const clearState = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setBibles('')
        setSpecifiedBibles(null)
        setCountry('')
        setPhone('')
        setError('')
        setLoading(false)
        setAmount('')
        setMonth('')
        setDay('')
        setSpecifiedBibles('')
    };

    const handleStripe = async (e) => {
        e.preventDefault();
        setLoading(true)
        setAmount(`$${dollarAmount}`)
        const stripe = await stripePromise;
        //rhapsody-bible.herokuapp.com
        const res = await submitToApi('https://biblemandate.onrender.com/api/biblemandate', {
            email,
            firstName,
            lastName,
            bibles: bibles?.value === 'Specify' ? specifiedBibles?.value?.toString() : bibles?.value?.toString(),
            country,
            phone,
            timeline,
            amount: dollarAmount.toString(),
            referrer
        })

        if (res?.data?.message) {
            setLoading(false)
            return setError(res.data.message)
        } else {
            let data = {
                email: res.data.email,
                firstName: res.data.firstName,
                lastName: res.data.firstName,
                country: res.data.country,
                phone: res.data.phone,
                timeline: res.data.timeline,
                amount: res.data.amount,
                donateLater: res.data.donateLater,
                url: window.location.href

            }
            localStorage.setItem("data", JSON.stringify(data))
        }

        const result = await stripe.redirectToCheckout({
            sessionId: res.data.stripeId,
        });



        if (result?.error) {
            swal("Error", "An error occured. Please try again later", "error")
        }
    }

    const submitForm = async (e) => {
        e.preventDefault();
        setAmount(`₦${nairaAmount}`)
        let data = localStorage.getItem("Userbibledata")
        let parseData = JSON.parse(data)


        const res = await submitToApi('https://biblemandate.onrender.com/api/biblemandate', {
            email: email ? email : parseData.email,
            firstName,
            lastName,
            bibles: bibles?.value === 'Specify' ? specifiedBibles?.value?.toString() : bibles?.value?.toString(),
            country,
            phone,
            timeline,
            amount: dollarAmount ? dollarAmount.toString() : nairaAmount,
            referrer,
            day,
            month
        })
        if (res?.data.message) {
            return setError(res.data.message)
        } else {
            swal("Successful!", "Your details have been saved", "success")
            clearState()
            window.location.reload()
            setDonateLater(false)
        }
    }

    const nairaAmount = bibles?.value === 'Specify' ? specifiedBibles?.value * 4200 : bibles?.value * 4200;

    const dollarAmount = bibles?.value === 'Specify' ? specifiedBibles?.value * 20 : bibles?.value * 20;

    const euroAmount = bibles?.value === 'Specify' ? specifiedBibles?.value * 20 : bibles?.value * 20;

    const poundsAmount = bibles?.value === 'Specify' ? specifiedBibles?.value * 20 : bibles?.value * 20;

    const randAmount = bibles?.value === 'Specify' ? specifiedBibles?.value * 150 : bibles?.value * 150;

    const cadAmount = bibles?.value === 'Specify' ? specifiedBibles?.value * 20 : bibles?.value * 20;

    const handleOptions = (selectedOptions) => {
        setBibles(selectedOptions)
    }


    const options = getOptions();

    // const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY

    // const componentProps = {
    //     email,
    //     nairaAmount: nairaAmount * 100,
    //     metadata: {
    //         firstName,
    //         lastName,
    //         phone,
    //     },
    //     publicKey,
    //     text: `Donate Now In ₦ (${nairaAmount ? nairaAmount : ''})`,
    //     onSuccess: () => swal("Donation Succesful!", "Your donation has been proccessed successfully", "success"),
    //     onClose: () => clearState(),
    // }

    const handleDonateLater = () => {
        !donateLater ? setDonateLater(true) : setDonateLater(false)
    }

    const handleBankTransferButton = (e) => {
        e.preventDefault()
        setShow(true)
    }


    const handleClose = async (e) => {
        e.preventDefault()
        await getAmount()
        setShow(false)
        swal({
            title: "Account Details",
            text: currencyChecker(currency, amount),
            button: {
                text: "Done",
            }
        }).then(async () => {
            const res = await submitToApi('https://biblemandate.onrender.com/api/biblemandate', {
                email,
                firstName,
                lastName,
                bibles: bibles?.value === 'Specify' ? specifiedBibles?.value?.toString() : bibles?.value?.toString(),
                country,
                phone,
                timeline,
                amount: amount.toString(),
                referrer,
                url: window.location.href
            })
            if (res?.data.message) {
                return setError(res.data.message)
            } else {
                swal("Successful!", "success")
                clearState()
                console.log("jdfjfj")
                setDonateLater(false)
                window.location.reload()
            }
        }).catch(err => swal("Error", err.message))
    }

    const handleSubmitDetailsButton = async () => {
        setAmount(`₦${nairaAmount}`)
        setLoading(true)
        console.log(true)
        const res = await submitToApi('https://biblemandate.onrender.com/api/biblemandate', {
            email,
            firstName,
            lastName,
            bibles: bibles?.value === 'Specify' ? specifiedBibles?.value?.toString() : bibles?.value?.toString(),
            country,
            phone,
            timeline,
            donateLater: parseDate(date.toString()),
            amount: nairaAmount.toString(),
            referrer,
            url: window.location.href
        })
        if (res?.data.message) {
            return setError(res.data.message)
        } else {
            swal("Successful!", "success")
            setLoading(false)
            clearState()
            window.location.reload()
        }
    }

    const months = [
        { value: 0, label: "Month of birth" },
        { value: 1, label: "January" },
        { value: 2, label: "Febuary" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
    ]

    const days = [
        { value: 0, label: "Day of birth" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" },
        { value: 15, label: "15" },
        { value: 16, label: "16" },
        { value: 17, label: "17" },
        { value: 18, label: "18" },
        { value: 19, label: "19" },
        { value: 20, label: "20" },
        { value: 21, label: "21" },
        { value: 22, label: "22" },
        { value: 23, label: "23" },
        { value: 24, label: "24" },
        { value: 25, label: "25" },
        { value: 26, label: "26" },
        { value: 27, label: "27" },
        { value: 28, label: "28" },
        { value: 29, label: "29" },
        { value: 30, label: "30" },
        { value: 31, label: "31" },

    ]
    let data = localStorage.getItem("Userbibledata")
    let parseData = JSON.parse(data)
    const config = {
        public_key: 'FLWPUBK-d7680be9103ea527a15b6114640bc87b-X',
        tx_ref: Date.now(),
        amount: nairaAmount,
        currency: "NGN",
        payment_options: 'card,mobilemoney,ussd',
        redirect_url: // specified redirect URL
            "https://rhapsodybible.org/biblemandatenetwork?success=true",
        customer: {
            email: email,
            phonenumber: phone,
            name: firstName
        },
        customizations: {
            title: 'Rhapsody Bible',
            description: 'Biblemandate Donation',
            logo: 'https://rhapsodybibles.org/wp-content/uploads/2020/03/Rhapsody-Bible-logo.png',
        },
    }

    const handleFlutterPayment = useFlutterwave(config);
    // const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY

    // const componentProps = {
    //     email,
    //     nairaAmount: nairaAmount * 100,
    //     metadata: {
    //         firstName,
    //         lastName,
    //         phone,
    //     },
    //     publicKey,
    //     text: `Donate Now In ₦ (${nairaAmount ? nairaAmount : ''})`,
    //     onSuccess: () => swal("Donation Succesful!", "Your donation has been proccessed successfully", "success"),
    //     onClose: () => clearState(),
    // }
    const handleFlutter = () => {
        return handleFlutterPayment({
            callback: (response) => {
                console.log(response);
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => { },
        });


    }
    const showformState = () => {
        setformShow(true)
    }
    const logout = () => {
        localStorage.clear();
        // you can also like localStorage.removeItem('Token');
        window.location.href = "/";
    }

    return (
        <Container className="py-4">
            <Checks handleClose={handleClose} show={show} setShow={setShow} currency={currency} setCurrency={setCurrency} />
            <Row>

                <Col md={6}>


                    <Image src={BMN} rounded fluid />

                </Col>

                <Col className="my-3" md={6}>
                    <div className="row">
                        <div className="col-md-6">
                            {(!localStorage.getItem("Userbibledata") && <h3 style={{ 'fontSize': '1.5rem' }}>Enlist Now</h3>)}


                        </div>
                        <div className="col-md-6">
                            {(localStorage.getItem("Userbibledata") ? null : !formShow && <h3 style={{ 'fontSize': '0.9rem' }}>Already have an account? <a className="a" onClick={showformState}>Login now</a></h3>)}

                        </div>

                    </div>
                    {
                        (localStorage.getItem("Userbibledata") ? null : formShow && <Signin />)}


                    {error && <Message variant='danger'>{error}</Message>}
                    {(localStorage.getItem("Userbibledata") && <h3 style={{ 'fontSize': '1.5rem' }}>Welcome back  {JSON.parse(localStorage.getItem("Userbibledata")).firstName} {JSON.parse(localStorage.getItem("Userbibledata")).lastName}!              <div>
                        <a href="#" onClick={logout}>LOGOUT</a>

                    </div></h3>)}

                    <Form onSubmit={submitForm}>
                        {(!localStorage.getItem("Userbibledata") &&

                            <>
                                <Form.Group controlId='firstName'>
                                    <Form.Control
                                        type='firstName'
                                        value={firstName}
                                        placeholder='*First Name'
                                        onChange={e => setFirstName(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId='lastName'>
                                    <Form.Control
                                        type='lastName'
                                        value={lastName}
                                        placeholder='*Last Name'
                                        onChange={e => setLastName(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId='email'>
                                    <Form.Control
                                        type='email'
                                        value={email}
                                        placeholder='*Email'
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <div class="row" style={{ marginBottom: '15px' }}>
                                    <div className="col">
                                        <select onChange={(e) => setMonth(e.target.value)} className="input-box form-control">
                                            {months.map((item, index) => (
                                                <option key={index} value={item.label}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col">
                                        <select onChange={(e) => setDay(e.target.value)} className="input-box form-control">
                                            {days.map((item, index) => (
                                                <option key={index} value={item.label}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <Form.Group controlId='country'>
                                    <CountryDropdown
                                        className="form-control"
                                        value={country}
                                        placeholder='*Country'
                                        onChange={(val) => setCountry(val)}
                                        required
                                    />
                                </Form.Group>



                                <Form.Group controlId='phone' className="mb-3">
                                    <PhoneInput country={countryString} inputStyle={{ "width": "100%" }} placeholder='Phone' value={phone} onChange={phone => setPhone(phone)} />
                                </Form.Group>
                            </>
                        )
                        }

                        <Form.Group controlId='bibles'>
                            <Select
                                value={bibles}
                                onChange={handleOptions}
                                options={options}
                                placeholder='*Select Bibles To Sponsor'
                                required
                            />
                        </Form.Group>

                        {bibles?.value === 'Specify' && (
                            <Form.Group controlId='bibles'>
                                <Form.Label>Specify number of Bibles to sponsor</Form.Label>
                                <Form.Control
                                    type='number'
                                    min='0'
                                    value={specifiedBibles?.value}
                                    placeholder='*Specify Number'
                                    onChange={e => setSpecifiedBibles({ value: e.target.value, })}
                                    required
                                />
                            </Form.Group>
                        )
                        }

                        {/* <Form.Group controlId="timeline">
                            <Form.Control
                                as="select"
                                value={timeline}
                                onChange={(e) => setTimeline(e.target.value)}
                            >
                                <option>How often do you want to donate?</option>
                                <option>One-Off</option>
                                <option>Monthly</option>
                                <option>Quarterly</option>
                                <option>Yearly</option>
                            </Form.Control>
                        </Form.Group> */}

                        {donateLater && (
                            <div>
                                <Form.Group>
                                    <Form.Check
                                        type='checkbox'
                                        label="Donate Now?"
                                        onClick={handleDonateLater}
                                    />
                                </Form.Group>
                                <Calendar
                                    className='my-3 mx-auto'
                                    value={date}
                                    onChange={setDate}
                                />
                            </div>
                        )}
                        {
                            donateLater ?
                                (
                                    <Button className='px-5 button' onClick={handleSubmitDetailsButton}>
                                        {loading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        ) : `Submit Details`}



                                    </Button>

                                ) :
                                (
                                    <div>
                                        {/* <PaystackButton
                                            className="btn btn-success button " {...componentProps}
                                        /> */}

                                        <Button className='px-5 buttons2' role='link' onClick={handleStripe} style={{ 'display': 'block', color: '#fff' }}>
                                            {loading ? (
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            ) : `Donate Now $ (${dollarAmount ? dollarAmount : ''})`}
                                        </Button>
                                        <Button className='px-5 buttons' style={{ backgroundColor: '#089c79' }} onClick={handleFlutter} style={{ color: '#fff', background: '#035640' }}>
                                            {`Donate Now ₦ (${nairaAmount ? nairaAmount : ''})`}
                                        </Button>

                                        <Button className='px-5 buttons' onClick={handleBankTransferButton} style={{ color: '#fff', background: '#035640' }}>
                                            {`Donate Now With Bank Transfer`}
                                        </Button>

                                        <Button className='px-5 buttons3' onClick={handleDonateLater} style={{ color: '#fff' }}>
                                            Enlist & Donate Later
                                        </Button>

                                    </div>
                                )
                        }
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default BibleMandateScreen
