import axios from 'axios'

export const submitToApi = async (link, payload) => {
  try {
    const res = await axios.post(link, payload);
    return res
  } catch (error) {
    return error.response
  }
}

export const parseDate = (string) => {
  let token = string.split(' ')
  let gmt = token[5].split('T')[1]
  const newDate = `${token[0]}, ${token[2]} ${token[1]} ${token[3]} ${token[4]} ${gmt}`
  return newDate
}

export const currencyChecker = (currency, amount) => {
  if (currency === "Pound") {
    return `
        POUNDS DONATIONS:
        Bank: Barclays Bank UK
        Name: Believers Loveworld Limited (LWPM Account)
        Bank Account:- 70091650
        Bank Sort Code:- 20-89-15
        IBAN:- GB68BUKB20891570091650
        SWIFT BIC:- BUKBGB22

        ${amount !== '' ? `Amount - ${amount}` : ''}
        `
  } else if (currency === "Euro") {
    return `
        EURO DONATIONS:
        Bank: Barclays Bank UK
        Name: Believers Loveworld Limited
        Bank Account:- 43263222
        Bank Sort Code:- 20-89-15
        IBAN:- GB36 BARC 2089 1543 2632 22
        SWIFT BIC: - BARCGB22

        ${amount !== '' ? `Amount - ${amount}` : ''}
        `
  } else if (currency === 'Dollar') {
    return `
        USA DOLLAR DONATIONS
        Bank - Wells Fargo
        Bank Address – 599 W Pioneer PKWY
        Grand Prairie TX 75051
        Account Name- LWP LLC
        Account no - 1409721246
        Routing no - 111900659
        Wire transfers domestic - 121000248
        Swift Code - WFBIUS6S

        ${amount !== '' ? `Amount - ${amount}` : ''}
        `
  } else if (currency === 'CAD') {
    return `
        CANADA DOLLAR DONATIONS
        Bank – TD Canada Trust
        Account name - Loveworld Publishing Inc.
        Account number – 19995277593

        ${amount !== '' ? `Amount - ${amount}` : ''}
        `
  } else if (currency === 'Rand') {
    return `
        RAND DONATIONS
        Bank - Standard Bank
        Account name - Loveworld Publishing Pty
        Account number – 420283706

        ${amount !== '' ? `Amount - ${amount}` : ''}
        `
  } else if (currency === 'Naira') {
    return `
        NAIRA DONATIONS
        Bank Name: Sterling Bank
        Account Name: LOVEWORLD PUBLISHING LTD - BIBLES
        Account Number: 0067734527

        ${amount !== '' ? `Amount - ${amount}` : ''}
        `
  }
}
