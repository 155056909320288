import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Image, Button, Spinner } from 'react-bootstrap';
import { CountryDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios'
// import { PaystackButton } from 'react-paystack'
import BMN from '../images/BMN.jpeg';
import Select from 'react-select';
import swal from '@sweetalert/with-react'
import Message from '../components/Message'
import { submitToApi, parseDate, currencyChecker } from '../services/Helpers';
import dotenv from 'dotenv'
import { loadStripe } from '@stripe/stripe-js';
import Calendar from 'react-calendar';
import { Countries } from '../services/Countries'
import Checks from '../components/Checks';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

dotenv.config();


 
const   Signin = ()=> {
     const [email, setEmail] = useState('');
     const [loading, setLoading] = useState(false)
     const [error, setError] = useState('')
   const submitForm = async (e) => {
        e.preventDefault();
        console.log("djddjj")
        const res = await axios.post('https://biblemandate.onrender.com/api/signin', {email})
        setLoading(true)

let data=res.data.user
if(data){
    setLoading(false)
    localStorage.setItem("Userbibledata",JSON.stringify(data))
    window.location.reload()
}else{
    setLoading(false)
    setError("Email not found")
}

    }
    return (
       <>
       
           {error && <Message variant='danger'>{error}</Message>}
                   <Form onSubmit={submitForm}  className="form-inline">
                        <Form.Group controlId='email' className="input_email">
                            <Form.Control
                                type='firstName'
                                value={email}
                                placeholder='Email Address'
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
    <button className='btn btn-primary' style={{marginLeft:'0.5rem'}}>
    {loading ? (
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            ) : `Sign in`}
                                    
    </button>

                        </Form><br/>
                    
                
       </>
    )
}

export default Signin
