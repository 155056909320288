import Footer from "./components/Footer";
import Header from "./components/Header";

import BibleMandateScreen from "./screens/BibleMandateScreen";
import { BrowserRouter as Router, Route } from 'react-router-dom';


const App = () => {
  
  return (
    <Router basename='/biblemandatenetwork'>
      <Header />
      <main>
      
        <Route exact path='/' component={BibleMandateScreen}/>
        <Route exact path='/ceamc' component={BibleMandateScreen}/>
        <Route exact path='/ceagz' component={BibleMandateScreen}/>
        <Route exact path='/celz5' component={BibleMandateScreen}/>
        <Route exact path='/celvz' component={BibleMandateScreen} />
        <Route exact path='/celz1' component={BibleMandateScreen} />
        <Route exact path='/celz2' component={BibleMandateScreen} />
        <Route exact path='/cewmc' component={BibleMandateScreen} />
        <Route exact path='/cecmc' component={BibleMandateScreen} />
        <Route exact path='/cessz1' component={BibleMandateScreen} />
        <Route exact path='/cesaz1' component={BibleMandateScreen} />
        <Route exact path='/celz3' component={BibleMandateScreen} />
        <Route exact path='/cenwz2' component={BibleMandateScreen} />
        <Route exact path='/cesez1' component={BibleMandateScreen} />
        <Route exact path='/cessz2' component={BibleMandateScreen} />
        <Route exact path='/ceukz4' component={BibleMandateScreen} />
        <Route exact path='/ceukz1' component={BibleMandateScreen} />
        <Route exact path='/ceukz2' component={BibleMandateScreen} />
        <Route exact path='/ceukz3' component={BibleMandateScreen} />
        <Route exact path='/celz4' component={BibleMandateScreen} />
        <Route exact path='/cenwz1' component={BibleMandateScreen} />
        <Route exact path='/cenez1' component={BibleMandateScreen} />
        <Route exact path='/cesez2' component={BibleMandateScreen} />
        <Route exact path='/cebz2' component={BibleMandateScreen} />
        <Route exact path='/ceenecz' component={BibleMandateScreen} />
        <Route exact path='/cekz' component={BibleMandateScreen} />
        <Route exact path='/blwcm' component={BibleMandateScreen} />
        <Route exact path='/ceavz' component={BibleMandateScreen} />
        <Route exact path='/cemcabeokuta' component={BibleMandateScreen} />
        <Route exact path='/ceabz' component={BibleMandateScreen} />
        <Route exact path='/cebz1' component={BibleMandateScreen} />
        <Route exact path='/cemwz' component={BibleMandateScreen} />
        <Route exact path='/ceoz' component={BibleMandateScreen} />
        <Route exact path='/cemcph' component={BibleMandateScreen} />
        <Route exact path='/cephz1' component={BibleMandateScreen} />
        <Route exact path='/cephz2' component={BibleMandateScreen} />
        <Route exact path='/cephz3' component={BibleMandateScreen} />
        <Route exact path='/cencz1' component={BibleMandateScreen} />
        <Route exact path='/cencz2' component={BibleMandateScreen} />
        <Route exact path='/ceswz1' component={BibleMandateScreen} />
        <Route exact path='/ceswz2' component={BibleMandateScreen} />
        <Route exact path='/ceswz3' component={BibleMandateScreen} />
        <Route exact path='/ceewcaz1' component={BibleMandateScreen} />
        <Route exact path='/ceewcaz2' component={BibleMandateScreen} />
        <Route exact path='/ceewcaz3' component={BibleMandateScreen} />
        <Route exact path='/ceewcaz4' component={BibleMandateScreen} />
        <Route exact path='/ceewcaz5' component={BibleMandateScreen} />
        <Route exact path='/ceewcaz6' component={BibleMandateScreen} />
        <Route exact path='/cechad' component={BibleMandateScreen} />
        <Route exact path='/cesaz2' component={BibleMandateScreen} />
        <Route exact path='/cesaz3' component={BibleMandateScreen} />
        <Route exact path='/cesaz4' component={BibleMandateScreen} />
        <Route exact path='/cesaz5' component={BibleMandateScreen} />
        <Route exact path='/ceumuahia' component={BibleMandateScreen} />
        <Route exact path='/ceabakaliki1' component={BibleMandateScreen} />
        <Route exact path='/ceenugu1' component={BibleMandateScreen} />
        <Route exact path='/ceowerri' component={BibleMandateScreen} />
        <Route exact path='/ceabakaliki2' component={BibleMandateScreen} />
        <Route exact path='/ceenugu2' component={BibleMandateScreen} />
        <Route exact path='/ceindia' component={BibleMandateScreen} />
        <Route exact path='/cenewdelhi' component={BibleMandateScreen} />
        <Route exact path='/cepune' component={BibleMandateScreen} />
        <Route exact path='/ceuae' component={BibleMandateScreen} />
        <Route exact path='/cecregion' component={BibleMandateScreen} />
        <Route exact path='/ceeeregion' component={BibleMandateScreen} />
        <Route exact path='/ceez2' component={BibleMandateScreen} />
        <Route exact path='/ceez3' component={BibleMandateScreen} />
        <Route exact path='/ceez4' component={BibleMandateScreen} />
        <Route exact path='/ceez1' component={BibleMandateScreen} />
        <Route exact path='/ceukr2z1' component={BibleMandateScreen} />
        <Route exact path='/ceukr2z2' component={BibleMandateScreen} />
        <Route exact path='/ceukr2z3' component={BibleMandateScreen} />
        <Route exact path='/ceukr2z4' component={BibleMandateScreen} />
        <Route exact path='/ceusr1z1' component={BibleMandateScreen} />
        <Route exact path='/ceusr1z2' component={BibleMandateScreen} />
        <Route exact path='/ceusregion2' component={BibleMandateScreen} />
        <Route exact path='/ceusregion3' component={BibleMandateScreen} />
        <Route exact path='/ceustexasz1' component={BibleMandateScreen} />
        <Route exact path='/ceustexasz2' component={BibleMandateScreen} />
        <Route exact path='/cesapele' component={BibleMandateScreen} />
        <Route exact path='/cecalgary' component={BibleMandateScreen} />
        <Route exact path='/lwghanazonea' component={BibleMandateScreen} />
        <Route exact path='/lvzconnect' component={BibleMandateScreen} />
        <Route exact path='/lvzconnect' component={BibleMandateScreen} />
        <Route exact path='/cemcw' component={BibleMandateScreen} />
        <Route exact path='/pastorpat' component={BibleMandateScreen} />
        <Route exact path='/f' component={BibleMandateScreen} />
        <Route exact path='/s' component={BibleMandateScreen} />
        <Route exact path='/c' component={BibleMandateScreen} />
        <Route exact path='/o' component={BibleMandateScreen} />
        <Route exact path='/j' component={BibleMandateScreen} />
        <Route exact path='/n' component={BibleMandateScreen} />
      </main>
      <Footer />
    </Router>
  );
}

export default App;
